import Rails from '@rails/ujs'

import { AttachmentUploader } from './helpers/attachment_uploader'
import { CancellationModal } from './aboalarm/cancellation/cancellation-modal'
import { CancellationPage } from './aboalarm/cancellation/cancellation-page'
import { CategoryLandingPage } from './aboalarm/static_pages/category-landing-page'
import { CheckoutConfirmationDownload } from './aboalarm/checkout/confirmation_download'
import { CheckoutPage } from './aboalarm/checkout/checkout-page'
import { Fingerprint } from './vendor/fingerprint'
import { GlideSliderAwards } from './aboalarm/components/glide-slider-awards'
import { Homepage } from './aboalarm/static_pages/home'
import { i18n } from './config/i18n'
import { LoginDialogPopup } from './aboalarm/components/login-dialog-popup'
import { LoginForm } from './login/login_form.js'
import { MobileCancellationPage } from './aboalarm/static_pages/mobile-cancellation'
import { NavigationMenu } from './aboalarm/components/navigation-menu'
import { ProfileForm } from './account/profile_form'
import { SelectRedirect } from './aboalarm/static_pages/select_redirect'
import { SignupConfirmationForm } from './signup/signup_confirmation_form'
import { SpecialCancellationPages } from './aboalarm/static_pages/special-cancellation-types-lp'
import { ThankYouPage } from './aboalarm/checkout/thank-you-page'
import { UnifiedSignupCreated } from './signup/unified_signup_created'
import { UnifiedSignupForm } from './signup/unified_signup_form'
import { UpdatePasswordForm } from './account/update_password/update_password_form'
import { VendorPage } from './aboalarm/static_pages/vendor-page'
import { VendorsOverview } from './aboalarm/static_pages/vendors-overview'
import { Voucher } from './cancellation/voucher'
import { WithdrawalPage } from './aboalarm/static_pages/withdrawal-page'

document.addEventListener('DOMContentLoaded', function () {
  if (Rails.fire(document, 'rails:attachBindings')) {
    Rails.start()
  }
  window.I18n = i18n

  new Fingerprint()
  new SelectRedirect()
  new CancellationPage()
  new CancellationModal()
  new AttachmentUploader()
  new Homepage()
  new VendorsOverview()
  new Voucher({
    hiddenClassName: 'u-display:none',
  })
  new CheckoutPage()
  new ThankYouPage()
  new CheckoutConfirmationDownload()
  new VendorPage()
  new SpecialCancellationPages()
  new SignupConfirmationForm()
  new WithdrawalPage()
  new LoginDialogPopup()
  new LoginForm()
  new UnifiedSignupCreated()
  new UnifiedSignupForm()
  new UpdatePasswordForm()
  new NavigationMenu()
  new MobileCancellationPage()
  new GlideSliderAwards()
  new CategoryLandingPage()
  new ProfileForm()
})
