import Inputmask from 'inputmask'

const BREAKPOINT = {
  phones: '320',
  tablets: '768',
  desktops: '1280',
  hd: '1920',
}

const media = {
  '>=phones': window.matchMedia('(min-width: ' + BREAKPOINT['phones'] + 'px)'),
  '<tablets': window.matchMedia('(max-width: ' + (+BREAKPOINT['tablets'] - 0.0001) + 'px)'),
  '>=tablets': window.matchMedia('(min-width: ' + BREAKPOINT['tablets'] + 'px)'),
  '>=desktops': window.matchMedia('(min-width: ' + BREAKPOINT['desktops'] + 'px)'),
  '>=hd': window.matchMedia('(min-width: ' + BREAKPOINT['hd'] + 'px)'),
}

export const isMobile = () => {
  if (window.matchMedia('all').addEventListener) {
    // for browsers that support matchMedia.addEventListener
    media['<tablets'].addEventListener('change', (mq) => {
      if (mq.matches) {
        return true
      }
    })
  } else {
    // matchMedia.addListener is deprecated, but some older browsers
    // still need it
    media['<tablets'].addListener((mq) => {
      if (mq.matches) {
        return true
      }
    })
  }
  if (media['<tablets'].matches) {
    return true
  }
  // assigned to global variable so that VWO can make use of it
  window.checkIfMobile = window.checkIfMobile || isMobile
  return window.innerWidth < +BREAKPOINT['tablets']
}

export const CallAnimationFrame = function (callback, interval) {
  let start = null
  const step = (timestamp) => {
    if (!start) start = timestamp
    var progress = timestamp - start
    if (progress < interval) {
      window.requestAnimationFrame(step)
    } else {
      callback()
    }
  }
  window.requestAnimationFrame(step)
}

export const Debounce = function (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const GetUrlParameter = function (location, name) {
  const parsedName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + parsedName + '=([^&#]*)')
  let results = regex.exec(location)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const IsValidEmailAddress = function (email) {
  const emailRule = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
  )
  return emailRule.test(email)
}

export const HasDiscountBeenApplied = function () {
  var ANALYTICS_DATA = ANALYTICS_DATA || {}
  return ANALYTICS_DATA.discount_added || document.querySelector('[data-context="discount-banner"]') ? 1 : 0
}

export const IsValidDate = function (dateString) {
  if (!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(dateString)) return false

  // Parse the date parts to integers
  const parts = dateString.split('.')
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const year = parseInt(parts[2], 10)
  const date = new Date(year, +month - 1, day)
  return Boolean(+date) && date.getDate() === day && date.getMonth() === +month - 1
}

export const IsValidYear = function (dateString) {
  if (!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(dateString)) return false

  const startYear = 1900
  const dateParam = StringToDate(dateString)
  const endYear = new Date().getFullYear() - 1 // valid years are between 1900 and current year - 1 according to product VOL-1588
  const year = dateParam.getFullYear()
  return startYear <= year && year <= endYear
}

export const InitInputMaskFor = function (domEl) {
  Inputmask({
    alias: 'datetime',
    inputFormat: I18n.t('frontend.shared.date_format'),
    placeholder: I18n.t('frontend.shared.date_placeholder'),
    outputFormat: 'dd.mm.yyyy',
    insertMode: true,
    inputmode: 'numeric',
    prefillYear: false,
    positionCaretOnClick: 'radixFocus',
    showMaskOnFocus: false,
    showMaskOnHover: false,
    autoUnmask: true,
  }).mask(domEl)
}

export const formatBytes = function (bytes, decimals) {
  if (bytes == 0) return '0 Bytes'
  var k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// Receive a string in the format dd.mm.yyyy
export const StringToDate = function (dateString) {
  const parts = dateString.split('.')
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const year = parseInt(parts[2], 10)

  return new Date(year, month - 1, day)
}

export const CurrentDateAtMidnight = function () {
  return new Date().setHours(0, 0, 0, 0)
}

export const CheckCostRegex = function (costValue) {
  const regExp = /(^\d{1,4})+(\,\d{1,2})?$/
  return regExp.test(costValue)
}

export const DispatchEventByName = function (eventName, domEl = window) {
  const e = new Event(eventName)
  domEl.dispatchEvent(e)
}
window.dispatchEventByName = DispatchEventByName

export const fetchZipcodeErrorMessageForCountry = function (country) {
  return country === 'DE'
    ? I18n.t('frontend.cancellation_form.errors.presence_postal_code_with_5digits')
    : I18n.t('frontend.cancellation_form.errors.presence_postal_code')
}

export const validateZipcodeForCountry = function (zipcode, country) {
  return country === 'DE' ? zipcode.match(/^\s*\d{5}\s*$/) : true
}

export const validateZipcodeForCountryForAboalarm = function (zipcode, country) {
  return country === 'DE' ? zipcode.match(/^\s*\d{5}\s*$/) : zipcode.length > 3
}

/**
 * Return the value from a cookie identified by the given key
 * @param {string} key The cookie key
 * @returns {(string|null)} The cookie value
 */
export const readCookie = function (name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
  return null
}
window.readCookie = readCookie

/**
 * Clear the cookie for the given key
 * @param {string} key The cookie key
 */
export const clearCookie = function (key) {
  setCookie(key, '', 0)
}

/**
 * Set a cookie using the given key as identifier
 * @param {string} key The key to identify the cookie
 * @param {string} value The value to be for the cookie
 * @param {number} [maxAge=315360000] How many seconds the cookie will least (defaults to 10 years)
 * @param {string} [path='/'] The path the cookie will be related to. To use it as a global cookie, use the default
 */
export const setCookie = function (key, value, maxAge, path) {
  if (maxAge === undefined) {
    maxAge = 315360000
  }
  if (path === undefined) {
    path = '/'
  }

  document.cookie = `${key}=${value}; max-age=${maxAge}; path=${path}; samesite=lax`
}

export const getGclidUUID = function () {
  const gclidValue = readCookie('_gcl_aw')
  if (!gclidValue) return null

  const mappedGclidValue = gclidValue.match(/^GCL\.\d+\.(.*)$/)
  if (!mappedGclidValue || mappedGclidValue.length != 2) return null

  return mappedGclidValue[1]
}

/**
 * Checks if the stored fields are valid. It performs a time-to-live (TTL); expiration lookup along with the persisted url mismatch lookup to determine the validity of the stored fields.
 *
 * @param {Object} storedFields - The stored fields object.
 * @returns {boolean} - Returns true if the stored field timestamp and scope are valid, false otherwise.
 */
export const IsStoredFieldsValid = function (storedFields) {
  if (!storedFields) return false

  let ttl = 60 * 60 * 1000 // 60 minutes
  let isExpired = storedFields.timestamp + ttl < Date.now()
  let isSameScope = storedFields.scope == window.location.pathname

  return !isExpired && isSameScope
}
