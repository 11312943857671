import { Amplitude } from '../integrations/amplitude'
import { formatBytes } from './utilities'
import {
  sessionStorageAvailable,
  sessionStorageSetItem,
  sessionStorageGetItem,
  sessionStorageRemoveItem,
} from '../vendor/storage_utilities'

export class AttachmentUploader {
  static CURRENT_MARKET() {
    return document.querySelector('[data-current-market]').dataset.currentMarket
  }

  static STORAGE_KEY() {
    return `${this.CURRENT_MARKET()}:uploadedAttachment`
  }

  static clearSessionStorage() {
    sessionStorageRemoveItem(this.STORAGE_KEY())
  }

  constructor() {
    this.el = document.querySelector('[data-context="attachment-section"]')
    if (!this.el) return

    this.apiUrl = this.el.dataset.host
    this.currentMarket = this.constructor.CURRENT_MARKET()
    this.attachmentFilenameField = document.querySelector('[data-context="attachment-filename"]')
    this.uploadStatusElem = this.el.querySelector('[data-context="attachment-upload-status"]')
    this.attachmentRemoveElem = this.el.querySelector('[data-context="attachment-remove"]')
    this.sessionStorageAvailable = sessionStorageAvailable()

    this.initUploadedAttachment()
    this.listenForAttachmentRemoval()
    if (this.sessionStorageAvailable) this.mountPersistedAttachment()
  }

  initUploadedAttachment() {
    this.uploadAttachmentField = this.el.querySelector('[data-context="upload-attachment-field"]')
    if (!this.uploadAttachmentField) return

    this.uploadAttachmentField.addEventListener('change', () => {
      const maxFileSize = 2 * 1024 * 1024 // 2MBs
      let file = this.uploadAttachmentField.files[0]
      if (!file) return

      if (file.size <= maxFileSize) {
        this.setUploadStatus('loading')
        this.uploadFileAttachment(file)
      } else {
        this.setAttachmentValue(null)
        this.setUploadStatus(
          'error',
          I18n.t('frontend.cancellation_form.errors.attachment_upload_size', { size: formatBytes(maxFileSize) })
        )
        Amplitude.logEvent('CS Form Page Attachment Upload File Size Error')
      }
    })
  }

  listenForAttachmentRemoval() {
    this.attachmentRemoveElem.addEventListener('click', () => {
      this.uploadAttachmentField.value = null
      this.setAttachmentValue(null)
      this.setUploadStatus('idle')
      this.hideAttachmentRemoveButton()
    })
  }

  showAttachmentRemoveButton() {
    this.attachmentRemoveElem.style.display = 'block'
  }

  hideAttachmentRemoveButton() {
    this.attachmentRemoveElem.style.display = 'none'
  }

  uploadFileAttachment(file) {
    /**
     * The upload happens in two steps:
     * It requests the signed URL to the attachment_upload_url endpoint. (This request should be fairly quick)
     * It uses the returned url to send the file directly to S3.
     * @param {file} arg file as passed by the file:input
     */
    fetch(`${this.apiUrl}/v2/files/attachment_upload_url?content_type=${file.type}`)
      .then((response) => response.json())
      .then((response) => {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': file.type },
          body: file,
        }
        this.setAttachmentValue(response.attachment_filename)
        return fetch(response.url, requestOptions)
      })
      .then((response) => {
        if (response.status !== 200) throw new Error(response.statusText)

        if (this.sessionStorageAvailable) this.persistAttachmentFile(file.name)
        this.setUploadStatus('success')
        this.showAttachmentRemoveButton()
        Amplitude.logEvent('CS Form Page Attachment Upload Success')
      })
      .catch((error) => {
        this.setAttachmentValue(null)
        this.setUploadStatus('error')
        this.hideAttachmentRemoveButton()
        Rollbar.error('Attachment upload failed', error)
        Amplitude.logEvent('CS Form Page Attachment Upload Error')
      })
  }

  persistAttachmentFile(fileName) {
    const sessionData = { fileName, attachmentFileName: this.attachmentFilenameField.value }
    const stringifiedData = JSON.stringify(sessionData)
    sessionStorageSetItem(this.constructor.STORAGE_KEY(), stringifiedData)
  }

  mountPersistedAttachment() {
    const stringifiedData = sessionStorageGetItem(this.constructor.STORAGE_KEY())
    const sessionData = JSON.parse(stringifiedData)
    if (sessionData) {
      this.setAttachmentValue(sessionData.attachmentFileName)
      this.setUploadStatus('draft', sessionData.fileName)
      this.showAttachmentRemoveButton()
    }
  }

  emitAttachmentUploaderCallback(status) {
    this.el.dispatchEvent(
      new CustomEvent(`${this.currentMarket}:attachmentUploaderUpdate`, {
        detail: { status },
      })
    )
  }

  setAttachmentValue(val) {
    if (val) return (this.attachmentFilenameField.value = val)
    this.attachmentFilenameField.removeAttribute('value')
  }

  setUploadStatus(status, message) {
    if (message) this.uploadStatusElem.textContent = message
    this.emitAttachmentUploaderCallback(status)
    this.uploadAttachmentField.classList.remove('c-form-element__input--is-invalid')
    this.uploadAttachmentField.classList.remove('c-form-element__input--is-valid')
    this.uploadAttachmentField.classList.remove('c-form-element__input--is-loading')
    this.uploadStatusElem.classList.remove('c-form-element__feedback--is-invalid')
    this.uploadStatusElem.classList.remove('c-form-element__feedback--has-info')
    switch (status) {
      case 'error':
        this.uploadAttachmentField.classList.add('c-form-element__input--is-invalid')
        this.uploadStatusElem.classList.add('c-form-element__feedback--is-invalid')
        break
      case 'success':
        this.uploadAttachmentField.classList.add('c-form-element__input--is-valid')
        break
      case 'loading':
        this.uploadAttachmentField.classList.add('c-form-element__input--is-loading')
        break
      case 'draft':
        this.uploadAttachmentField.classList.add('c-form-element__input--is-valid')
        this.uploadStatusElem.classList.add('c-form-element__feedback--has-info')
        break
    }
  }
}
